.top-65{
    top:60px !important
}

/* @keyframes spinner {
     0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid green;
    border-radius: 50%;
    border-top-color: green;
    animation: spinner 2s linear infinite;
  } */

 .loading {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid green; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } 

  .p-datepicker table td > span.p-highlight {
    color: white;
    background: red !important;
  }
  a{
    text-decoration: none;
    color: black !important ;
  }