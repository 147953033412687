html {
    font-size: 1rem;
    height: 100%;
}

.p-datatable-wrapper {
  overflow-x: scroll !important;
}

body {
    font-family: var(--font-family);
    background-color: var(--surface-ground);
    color: var(--text-color);
    padding: 0;
    margin: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
//    font-family: 'Poppins', sans-serif;
}


.documentation i {
    background-color: var(--surface-card);
    color: #2196f3;
    font-family: Monaco,courier,monospace;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 4px;
    letter-spacing: .5px;
    border-radius: 3px;
    font-weight: 600;
    margin: 0 2px;
}

a{
    text-decoration: none;
}

@media only screen and (min-width: 600px) {
    .dash-spin {
      width: 300;
      height: 300;
    }
  }


.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar handle */
  border-radius: 5px; /* Make the scrollbar handle round */
}

/* Firefox scrollbar styles */
.scrollable-container {
  scrollbar-width: thin; /* Adjust the width of the scrollbar */
}

.box-shadow-css {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}