@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
  }

// .poppins-font {
//     font-family: 'Poppins', sans-serif;
//   }

.lga-card {
  background: #ffffff;
  border-radius: 6px;
  padding: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  transition: box-shadow 0.3s ease;
  text-align: left;
}

.lga-card:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.lga-name {
  font-size: 14px;
  color: #666;
}

.lga-count {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-top: 8px;
}

.lga-line-container {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.lga-line {
  flex-grow: 1;
  margin-right: 8px;
}

.img-fit {
  background-position: center center;
  background-size: cover;
  object-fit: cover;
}

