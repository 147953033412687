@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.menu-item {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    color: #333 !important;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;

    .icon {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        color: #4caf50;
        transition: color 0.3s ease;
    }
}

.menu-item:hover {
    background-color: #0e7706;
    color: #fff !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;

    .icon {
        color: #fff;
    }
}

.menu-item.active, 
.menu-item.active:hover {
    background-color: #0e7706;
    color: #fff !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;

    .icon {
        color: #fff;
    }
}

span {
    font-size: 14px;
    font-weight: 600;
}

.c-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}
